.data-table {
    width: 99.5%;
}

.date-picker-container {
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.25);
    border-radius: 7px;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    -ms-border-radius: 7px;
    -o-border-radius: 7px;
}