.form-body {
    margin: 0;
    /* Réinitialiser la marge du corps pour éviter tout débordement */
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.custom-alert-container {
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 1000;
  }
  
  .custom-alert {
    width: 300px;
    margin-bottom: 10px;
  }

.form-support {
    display: flex;
    height: 90vh;
    width: 90%;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.design-space {
    width: 50%;
}

.form-space {
    padding-left: 10%;
    padding-top: 12.5%;
}

.welcome-back {
    width: 100%;
    margin-bottom: 50px;
    margin-left: 30%;
    font-weight: bold;
}

.form-input {
    height: 40px;
    width: 160%;
    background-color: #ededed;
    border: none;
    outline: none;
text-indent: 10px;

}

.form-input::placeholder {
text-indent: 10px;
}

.btn-connecter {
    color: white;
    background: #015677;
    margin-top: 30px;
    width: 160%;
    height: 45px;
    border-radius: 5px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 600;
    outline: none;
    border: none;
}