@font-face {
    font-family: "Kumbh Sans";
    src: url("../fonts/Kumbh_Sans/KumbhSans.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

* {
    margin: 0;
    padding: 0;
}

.down-body {
    margin-top: 0px;
    margin-left: 250px;

}

.to-right{
}

.left-part {
    width: 44%;
    margin-top: 10%;
}

.vehicule-image {
}

.right-part {
    width: 56%;
    margin-top: -15px;
}

.info-vehicle-loader {
    text-align: center;
    margin-top: 15%;
}

.input-immatriculation-vehicule {
    color: white;
    text-align: center;
    font-weight: 600;
    width: 425px;
    height: 50px;
    border-radius: 10px;
    background: #29C294;
    border: none;
    outline: none;
    margin-top: .5%;
}

.input-immatriculation-vehicule::placeholder {
    color: white;
    text-align: center;
}

.inputs-row {
    display: flex;
    flex-direction: row;
}

.info-vehicle-input-container {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 600;
}

.info-vehicle-input-container label {
    font-weight: 600;
}

.info-vehicle-input {
    width: 200px;
    height: 43px;
    border-radius: 5px;
    background: #EDEDED;
    outline: none;
    border: none;
    text-align: center;
}


.info-vehicle-input::placeholder {
    text-align: center;
    color: black;
    font-family: Kumbh Sans;
}

.readonly {
    color: grey;
}

.btn-enregistrer {
    color: white;
    background: #015677;
    margin-top: 15px;
    margin-left: 20px;
    width: 420px;
    height: 45px;
    border-radius: 5px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 600;
    outline: none;
    border: none;
}

.btn-supprimer {
    color: white;
    background-color: #e0021a;
    margin-top: 30px;
    margin-left: 20px;
    width: 200px;
    height: 45px;
    border-radius: 5px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 600;
    outline: none;
    border: none;
}

.info-vehicle-input-statut-container {
    width: 23.5%;
}

.range-date-span {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 600;
    margin-top: 5%;
}

.input-with-unit {
    position: relative;
}

.input-with-unit::after {
    content: "Km";
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
}

.input-with-unit-fcfa {
    position: relative;
}

.input-with-unit-fcfa::after {
    content: "Fcfa";
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
}

.input-with-unit .info-vehicle-input, .input-with-unit-fcfa .info-vehicle-input {
    text-align: left;
    text-indent: 10px;
}

/*select-design*/
.custom-select-wrapper {
    position: relative;
}

.custom-select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    cursor: pointer;
    padding: 10px;
    font-size: 16px;
    border-radius: 4px;
    outline: none;
    width: 200px;
}

.modal-motif-input {
    
}