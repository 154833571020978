.no-margin {
    margin: 0;
    padding: 0;
}

@font-face {
    font-family: 'Kanit';
    /* Le nom que vous souhaitez utiliser pour la police */
    src: url('../fonts/Kumbh_Sans/KumbhSans.ttf') format('truetype');
    /* Chemin vers le fichier de police dans le dossier public */
}

.navbar-body {
    height: 100vh;
    width: 195px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: #015677;
    position: fixed;
    margin-right: 220px;
}

.des-underline{
    text-decoration: none;
}

.phone-navbar{
    display: flex;
    flex-direction: row;
}

.items {
    height: 100vh;
    width: 100%;
    margin-top: 25px;
    margin-left: 6px;
    margin-right: 10px;
}

.choose-item {
    background-color: white;
    height: 35px;
    width: 100%;
    border-radius: 5px;
    padding-top: 6px;
    padding-left: 13px;
    cursor: pointer;
}

.to-flex {
    display: flex;
    flex-direction: row;
}

.to-bold {
    color: #000;
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.item-text{
    margin-left: 10px;
}

.to-top{
    margin-top: -3px;
}

.other-items{
    height: 35px;
    width: 100%;
    border-radius: 5px;
    padding-top: 6px;
    padding-left: 13px;
    cursor: pointer;
}


.other-items-font{
    margin-left: 13.5px;
    color: white;
}

.user-div {
    height: 8vh;
    width: 100%;
    background-color: whitesmoke;
    opacity:  0.9;
    display: flex;
    align-items: center;    
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.user-image {
    width: 40px; /* Ajustez la taille du cercle selon vos besoins */
    height: 40px; /* Ajustez la taille du cercle selon vos besoins */
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    box-shadow: inset 0 0 10px #ccc; /* Ajout de l'ombre interne */
}

.logout-item{
    background-color: #E0021A;
    height: 5.5vh;
    width: 100%;
    border-radius: 5px;
    padding-top: 4px;
    padding-left: 15px;
    margin-top: 32vh;
    cursor: pointer;
}