.right-side {
    margin-left: 195px;
    width: 80%;
}

.toolbar {
    margin-left: 1.6%;
    margin-bottom: 2%;
    display: flex;
    flex-direction: row;
}

.search-bar {
    width: 300px;
    height: 45px;
    border-radius: 10px;
    font-weight: 600;
    background: #FFF;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
    text-indent: 20px;
    outline: none;
    border: none;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.search-bar::placeholder {
    text-indent: 20px;
}

.select-option {
    width: 200px;
    height: 45px;
    border-radius: 10px;
    font-weight: 600;
    background: #FFF;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
    outline: none;
    border: none;
    margin-left: 15%;
    text-indent: 15px;
}

.add-vehicle-btn {
    height: 45px;
    width: 170px;
    border: none;
    background-color: #015677;
    color: white;
    margin-left: 60px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.download-icon {
    border-radius: 5px;
    background: #015677;
    height: 45px;
    width: 45px;
    margin-left: 5%;
    padding-top: 8px;
    padding-left: 10.5px;
    cursor: pointer;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.to-margin {
    margin-left: 1px;
}

.vehicle-item {
    height: 210px;
    width: 266px;
    flex-shrink: 0;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
    margin-bottom: 40px;
}

.vehicle-image {
    height: 68%;
}

.zoom-on-hover {
    transition: transform 0.3s ease;
    /* Ajoute une transition pour un effet de zoom plus fluide */
}

.zoom-on-hover:hover {
    transform: scale(1.1);
    /* Applique un facteur d'échelle de 1.1 au survol, ce qui donne un effet de zoom */
}

.vehicle-info {
    color: white;
    height: 27.5%;
    background-color: #015677;
    padding-top: 1%;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;

}

.vehicle-info-in {
    display: flex;
    margin-left: 8%;
}

.vehicle-immatriculation {
    width: 200px;
    font-size: 18px;
    font-weight: 600;
}

.vehicle-info-left {
    width: 38%;
    text-align: left;
}

.vehicle-info-right {
    margin-left: 35px;
    margin-bottom: 50px;
}

.payer-recette {
    margin-top: 5px;
    padding-top: 7.5%;
    width: 110px;
    height: 40px;
    border-radius: 5px;
    background: #29C294;
    color: white;
    cursor: pointer;
}

.a-jour{
    margin-top: 5px;
    padding-top: 7.5%;
    width: 110px;
    height: 40px;
    border-radius: 5px;
    background: white;
    color: black;
    font-weight: bold;
}

.total-div {
    font-size: 19px;
    font-weight: bold;
    color: #015677;
}

.toogle-state {
    display: flex;
}

.toogle {
    width: 200px;
    height: 50px;
    flex-shrink: 0;
    border-radius: 10px;
    background: #F4F4F4;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.25);
    cursor: pointer;
}

.tout-regler {
    background-color: #015677;
    color: white;
}

.avec-manquant {
    background-color: #E0021A;
    color: white;
}

.payer-recette-body {
    background-color: #015677;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 100%;
    color: white;
    height: 240px;
}

.payer-recette-inputs {
    padding-top: 25px;
}

.payer-recette-input {
    padding-top: 50px;
    margin-left: 30px;
    width: 160px;
    height: 40px;
    flex-shrink: 0;
    border-radius: 5px;
    background: #FFF;
    border: none;
    outline: none;
}

.payer-recette-date-label {
    margin-left: 50px;
}

.payer-recette-date-input {
    margin-left: 63px;
}

.btn-payer {
    color: white;
    background: #015677;
    margin-left: 20px;
    width: 110px;
    height: 40px;
    border-radius: 5px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 600;
    outline: none;
    border: none;
}

.btn-fermer {
    color: white;
    background: #919191;
    margin-left: 20px;
    width: 110px;
    height: 40px;
    border-radius: 5px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 600;
    outline: none;
    border: none;
}