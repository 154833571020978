.intervention-toggle-state {
    width: 30%;
    display: flex;
    flex-direction: row;
    background-color: whitesmoke;
    padding: 0.3%;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.type {
    height: 45px;
    width: 50%;
    border-radius: 10px;
    cursor: pointer;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.selected-type {
    background-color: #015677;
    color: white;
}

.other-type:hover{
    background-color: #e9e9e9;
}