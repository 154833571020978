.km-unit {
  position: absolute;
  margin-top: 9px;
  margin-left: -40px;
}


.checkboxes {
  display: flex;
}

.checkbox-wrapper-4 * {
  box-sizing: border-box;
}

.checkbox-wrapper-4 .cbx {
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  padding: 6px 8px;
  border-radius: 6px;
  overflow: hidden;
  transition: all 0.2s ease;
  display: inline-block;
}

.checkbox-wrapper-4 .cbx:not(:last-child) {
  margin-right: 6px;
}

.checkbox-wrapper-4 .cbx span {
  float: left;
  vertical-align: middle;
  transform: translate3d(0, 0, 0);
}

.checkbox-wrapper-4 .cbx span:first-child {
  position: relative;
  width: 18px;
  height: 18px;
  border-radius: 4px;
  transform: scale(1);
  border: 1px solid #cccfdb;
  transition: all 0.2s ease;
  box-shadow: 0 1px 1px rgba(0, 16, 75, 0.05);
}

.checkbox-wrapper-4 .cbx span:first-child svg {
  position: absolute;
  top: 3px;
  left: 2px;
  fill: none;
  stroke: #fff;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 16px;
  stroke-dashoffset: 16px;
  transition: all 0.3s ease;
  transition-delay: 0.1s;
  transform: translate3d(0, 0, 0);
}

.checkbox-wrapper-4 .cbx span:last-child {
  padding-left: 8px;
  line-height: 18px;
}

.checkbox-wrapper-4 .cbx:hover span:first-child {
  border-color: #29c294;
}

.checkbox-wrapper-4 .inp-cbx {
  position: absolute;
  visibility: hidden;
}

.checkbox-wrapper-4 .inp-cbx:checked + .cbx span:first-child {
  background: #29c294;
  border-color: #29c294;
  animation: wave-4 0.4s ease;
}

.checkbox-wrapper-4 .inp-cbx:not(:checked) + .cbx span:first-child {
  background: #e0021a;
  background-color: #e0021a;
  border: none;
  animation: wave-4 0.4s ease;
}

.checkbox-wrapper-4 .inp-cbx:checked + .cbx span:first-child svg {
  stroke-dashoffset: 0;
}

.checkbox-wrapper-4 .inline-svg {
  position: absolute;
  width: 0;
  height: 0;
  pointer-events: none;
  user-select: none;
}

@media screen and (max-width: 640px) {
  .checkbox-wrapper-4 .cbx {
    width: 100%;
    display: inline-block;
  }
}

@-moz-keyframes wave-4 {
  50% {
    transform: scale(0.9);
  }
}

@-webkit-keyframes wave-4 {
  50% {
    transform: scale(0.9);
  }
}

@-o-keyframes wave-4 {
  50% {
    transform: scale(0.9);
  }
}

@keyframes wave-4 {
  50% {
    transform: scale(0.9);
  }
}

@keyframes pop {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.9);
  }

  100% {
    transform: scale(1);
  }
}

.label {
  height: 50px;
  width: 145px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  -webkit-transition: -webkit-transform 0.4s;
  transition: -webkit-transform 0.4s;
  transition: transform 0.4s;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

#checkbox {
  display: none;
}

#checkbox:checked ~ .label::before {
  width: 70px;
  left: 70px;
  margin-left: -10px;
  margin-top: -10px;
  background: url("../../images/intervention/voiturebrisee.png");
  background-size: 80px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.label::before {
  position: absolute;
  content: "";
  height: 40px;
  width: 57.5px;
  margin-left: 5px;
  margin-top: 7px;
  background: url("../../images/intervention/voiture.png");
  background-size: 60px;
  left: 10px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.red-circle {
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: red;
  transform: translate(-50%, -50%);
  pointer-events: none;
  /* Pour ne pas bloquer les interactions avec les éléments sous le cercle */
}

option {
  height: 20px;
  border: 1px solid;
  border-radius: 4px;
  background-color: #f5f5f5;
  color: #333;
  width: 310px;
  outline: none;
  margin-bottom: 20px;
}

option:hover {
  background: red;
}

.btn {
  border: 1px solid black;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 0px;
  width: 140px;
  height: 40px;
  transform: scale(1);
  border-style: none;
  outline: none;
  cursor: pointer;
  background: #e8e8e8;
  background: linear-gradient(
    45deg,
    rgb(228, 232, 236) 0%,
    rgb(234 234 234) 100%
  );
  box-shadow: -2px -7px 10px #f1f1f1, 4px 6px 10px #d4d7da;
  transition: all 0.3s;
}

.btn:active {
  background: rgb(218, 221, 224);
  background: linear-gradient(
    45deg,
    rgb(218, 221, 224) 0%,
    rgb(241, 241, 241) 100%
  );
  box-shadow: -2px -7px 10px #d4d7da, 4px 6px 10px #f1f1f1,
    inset -2px -7px 10px #f1f1f1, inset 4px 6px 10px #d4d7da;
  transition: all 0.3s;
  transform: scale(0.95);
}
