.user-frame {
    display: flex;
}

.photo-profil {
    height: 250px;
    width: 250px;
    margin-top: 15%;
    margin-left: 10%;
    cursor: pointer;
    border: 1px solid #015677;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.add-plus {
    height: 250px;
    width: 250px;
    margin-top: 35px;
    margin-left: 60px;
    margin-bottom: -10px;
}

.image-frame {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    border: 1px solid #D9D9D9;
    z-index: 0;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.image {
    width: 100px;
    height: 100px;
    border-radius: 5px;
    border: 2px solid #D9D9D9;
}

.add-image-button, .add-video-button {
    width: 140px;
    height: 140px;
    border-radius: 5px;
    border: 1px solid #015677;
    cursor: pointer;
    margin-right: 20px;
}

.add-image-button{
    margin-left: 20px;
}

.add-text {
    color: #015677;
    margin-top: 20px;
    margin-left: 9px;
    font-size: 15px;
}

.image-container {
    height: 160px;
    max-width: 880px; 
    overflow-y: auto; 
}
  
.image-frame {
    position: relative;
}

.file-name-overlay {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 5px;
    margin-left: 1px;
    margin-top: 1px;
    background: rgba(0, 0, 0, 0.6);
    color: white;
    font-size: 16px;
    text-align: center;
    padding: 10px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.image-frame:hover .file-name-overlay {
    display: block;
}

.on-image-icons {
    display: none;
    position: absolute;
    border-radius: 50%;
    padding: 5px;
    cursor: pointer;
}

.image-frame:hover .on-image-icons {
    display: block;
}

.delete-icon {
    margin-left: 60px;
}


.to-center {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -50%;
    margin-left: 20px;
}

.mt-10 {
    margin-top: 13%;
}