.toggle-state {
    width: 20%;
    display: flex;
    flex-direction: row;
    margin-left: 20px;
    border-radius: 5px;
    background-color: whitesmoke;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.25);
}

.detail {
    height: 45px;
    width: 50%;
    border-radius: 5px;
    cursor: pointer;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.selected-detail {
    background-color: #015677;
    color: white;

}

.other-detail {
    
}

.other-detail:hover {
    background-color: #e9e9e9;
    transition: 0.8s;
    -webkit-transition: 0.8s;
    -moz-transition: 0.8s;
    -ms-transition: 0.8s;
    -o-transition: 0.8s;
}